import {
    AfterViewChecked,
    Component,
    ComponentRef,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { Location } from "@angular/common";
import { AppRoutingStateService } from "./routes/app-routing-state.service";
import { AccountIdentity, IdentityService } from "../core/identity.service";
import { Logger } from "../core/logger/logger";
import { FeatureService } from "../core/feature.service";
import { forkJoin, from, Observable } from "rxjs";
import { filter as rxJsFilter, first as rxJsFirst, mergeMap, take, takeUntil, tap } from "rxjs/operators";
import { GlobalSettingService } from "../core/global-setting.service";
import { ModalLaunchService } from "../core/modal-launch.service";
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { AppStateService } from "./app-state.service";
import { ROUTE_PARTNER_ROOT } from "./routes/routes.partners";
import { AuthGuard } from "../common-app/auth/auth.guard";
import { Browser } from "../core/browser";
import { SubscriptionAbstract } from "../core/subscription.abstract";
import {
    getConfigByPartnerId,
    getPartnerIdFromRootRoute,
    isFooterTogglingDisabledForPartner
} from "./modules/partners/partners-config/partners.config";
import { AuthTypes } from "./views/authentication-view/authentication-types";
import { getQueryParams, parseQueryParams } from "../core/utility-functions";
import { DailyGoalProgressService } from "../activity-app/shared-activity/daily-goal-progress.service";
import { ProgressQueueService } from "../common-app/progress-app/progress-queue.service";
import { ReportCardEvent } from "../common-app/progress-app/event-factory.service";
import { AnalyticsService } from "../core/analytics";
import { RegWallService } from "../activity-app/shared-activity/reg-wall.service";
import { EligibilityService } from "../core/eligibility.service";
import { InstrumentationService } from "../core/instrumentation/instrumentation.service";
import { LazyLoaderService } from "../core/lazy-loader.service";
import { FooterComponent } from "./modules/footer/footer.component";
import { StorageCache } from "../core/storage-cache";
import { get, isFunction, isUndefined, split, toNumber } from "lodash-es";
import { TwaEventHandler } from "../activity-app/event-handler/twa-event-handler";
import { FreelessonPromoAppModalComponent } from "./modules/freelesson-promo-app/freelesson-promo-app-modal.component";
import { SchedulerApp } from "./modules/scheduler-app-v2/scheduler-app.helper";
import { CHANNEL_OPEN_INITIAL_STEPS_MODAL, ChannelService } from "../core/channel.service";
import { PushNotificationService } from "../shared/push-notification/push-notification.service";
import { APPLICATIONSTATELAUNCHED_ACTIVE, EVENT_TYPE_OPEN } from "../model/types/account-contactinformation";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Instrumentation } from "../core/instrumentation/instrumentation";
import { ThemeService } from "../core/theme.service";
import { ROUTE_AUTHENTICATION, ROUTE_HOME, ROUTE_MY_ENGLISH } from "./routes/routes";

@Component({
    selector: "ec-pwa-v2-landing-app",
    templateUrl: "landing-app.component.html",
    styleUrls: ["landing-app.component.scss"]
})
export class LandingAppComponent extends SubscriptionAbstract implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild("mainEl", { static: false, read: ElementRef }) mainEl: ElementRef;
    @ViewChild("footerRef", { read: ViewContainerRef }) footerRef: ViewContainerRef;
    @ViewChild("pushNotificationRef", { read: ViewContainerRef }) pushNotificationRef: ViewContainerRef;
    @ViewChild("globalNotificationRef", { read: ViewContainerRef }) globalNotificationRef: ViewContainerRef;
    @ViewChild("cookieConsentRef", { read: ViewContainerRef }) cookieConsentRef: ViewContainerRef;
    @ViewChild("headerNavigationAppRef", { read: ViewContainerRef }) headerNavigationAppRef: ViewContainerRef;
    @ViewChild("twilioChatDebuggerRef", { read: ViewContainerRef }) twilioChatDebuggerRef: ViewContainerRef;
    @ViewChild("twilioSiteChatRef", { read: ViewContainerRef }) twilioSiteChatRef: ViewContainerRef;


    private touchForDesktopEnabled: boolean = false;
    private touchForDesktopInitialized: boolean = false;
    // *** Loading States ***
    private settingsLoading: boolean = false;
    // *** Root query params ***
    private rootQueryParams: { [key: string]: any } = {};
    // *** Cookie Consent Status ***
    private cookieConsent = new StorageCache<boolean>("CookieConsent");
    // *** Lazy Loaded Component Ref ***
    private footerComponentRef: ComponentRef<any>;
    private headerNavigationAppComponentRef: ComponentRef<any>;
    private twilioChatDebuggerComponentRef: ComponentRef<any>;
    private twilioChatComponentRef: ComponentRef<any>;

    private logger = new Logger();

    constructor(private identityService: IdentityService,
                private featureService: FeatureService,
                private globalSettingService: GlobalSettingService,
                private analyticsService: AnalyticsService,
                private elementRef: ElementRef,
                private router: Router,
                private authGuardService: AuthGuard,
                private modalLaunchService: ModalLaunchService,
                private dailyGoalProgressService: DailyGoalProgressService,
                private appStateService: AppStateService,
                private location: Location,
                private progressQueueService: ProgressQueueService,
                private appRoutingStateService: AppRoutingStateService,
                private regWallService: RegWallService,
                private eligibilityService: EligibilityService,
                private modalService: NgbModal,
                private themeService: ThemeService,
                private instrumentationService: InstrumentationService,
                private lazyLoaderService: LazyLoaderService,
                private channelService: ChannelService,
                private pushNotificationService: PushNotificationService,
                private route: ActivatedRoute) {
        super();
    }

    @HostListener("window:beforeinstallprompt", ["$event"]) onBeforeInstallPrompt(event) {
        event.preventDefault();
    }

    ngOnInit(): void {
        this.instrumentationService.setAngularStartTime();
        this.settingsLoading = true;
        // Keep the promise conversion. If this is a subscription, it'll stay in memory always.
        this.authGuardService.setRedirectionUrl([ROUTE_AUTHENTICATION, AuthTypes.LOGIN]);
        this.setRootQueryParams();
        this.setElementRefParams();
        this.setFlags();
        this.launchTwa();
        this.trackPushNotifications();
        this.initializeSettings()
            .pipe(
                rxJsFirst()
            )
            .subscribe(() => {
                this.router.initialNavigation();
                // Subscriptions should be initiated after initializing the settings;
                this.initializeSubscriptions();
                this.settingsLoading = false;
                this.lazyLoadAsyncComponents();
                this.initializeUrlParamActions();
                return;
            });
        this.initializeTouchForDesktop().then(() => this.touchForDesktopEnabled = true);
    }

    ngAfterViewChecked(): void {
        this.setMainElPosition();
    }

    private initializeUrlParamActions(): void {
        const showFlPromoUrlParam = get(this.appStateService.getFormData(), AppStateService.SHOW_FL_PROMO);
        if (this.isUrlParamTruthy(showFlPromoUrlParam) && !this.modalService.hasOpenModals()) {
            this.eligibilityService.initialize().subscribe(() => this.openFreeLessonPromo());
            return;
        }
        // Other url param actions should be written here
    }

    private initializeSettings(): Observable<any> {
        this.globalSettingService.initializeFromElement(this.elementRef, false);
        this.identityService.initializeFromElement(this.elementRef, false);
        return from(this.initializeIdentity()).pipe(
            mergeMap(() =>
                forkJoin([
                    this.featureService.getInitialize$().pipe(rxJsFirst(initialized => initialized)),
                    from(this.regWallService.initialize())
                ])
            ),
            tap(() => {
                this.themeService.initialize(this.featureService.getFeature("siteTheme"));
                // NOTE: you can have subscriptions inside tap if they are side effects
                // need to think async, instead of sync
                // don't make unnecessary blocking calls!
                this.eligibilityService.initialize().subscribe(() => {
                    this.eligibilityService.updateEligibleSessionTypesAfterFeatures();
                });
                this.analyticsService.initializeTrackers(this.appStateService.getGeolocation(), true, {
                    appVersion: this.appStateService.getAppVersionBasedOnDevice(),
                    appId: this.appStateService.getAppIdBasedOnDevice(),
                    isPertoPluginEnabled: this.isPertoPluginEnabled()
                });
            })
        );
    }

    private initializeSubscriptions(): void {
        // Daily goal progress is updated at app root level
        this.progressQueueService
            .getObservable(ProgressQueueService.EVENT_SUCCESS_SENT)
            .pipe(takeUntil(this.getDestroyInterceptor()))
            .subscribe((reportCardEvents: ReportCardEvent | ReportCardEvent[]) => {
                // Video and course route progresses updated from activity app to make activityProgress and activitySettings
                // visible to the dialogGoalProgressService
                if (this.identityService.isAnonymous()) {
                    return;
                }
                if (
                    !this.appRoutingStateService.isCourseRouteActive() &&
                    !this.appRoutingStateService.isCourseRouteActive()
                ) {
                    this.dailyGoalProgressService.updateAccountPointsToday(reportCardEvents);
                }
            });
        // Subscription to router events required to lazy load some modules (Ex: headerNavigationModule) which are conditionally restricted.
        this.router.events.pipe(
            takeUntil(this.getDestroyInterceptor()),
            rxJsFilter((event) => event instanceof RouterEvent)
        ).subscribe((event): void => {
            if (event instanceof NavigationEnd) {
                this.lazyLoadHeaderNavigationModule();
            }
        });

        if (!this.isAnonymous()) {
            // progress shouldn't be a blocking call
            this.dailyGoalProgressService.initialize().pipe(takeUntil(this.getDestroyInterceptor())).subscribe();
        }
    }

    private initializeTouchForDesktop(): Promise<any> {
        if (this.touchForDesktopInitialized) {
            return Promise.resolve();
        }
        this.touchForDesktopInitialized = true;
        // Ref: https://hammerjs.github.io/touch-emulator/
        return import(
            /* webpackMode: "eager" */
            "hammer-touchemulator"
        ).then(module => {
            if (!module) {
                return;
            }
            const touchEmulator = (window["TouchEmulator"] = module);
            if (isFunction(touchEmulator)) {
                this.logger.log("Touch for desktop initialized...");
                touchEmulator();
            }
        });
    }

    getLocation(): string[] {
        return split(this.location.path(), "?");
    }

    getGeolocation(): string {
        return this.appStateService.getGeolocation();
    }

    setMainElPosition(): void {
        const mainEl = get(this.mainEl, "nativeElement");
        if (!mainEl) {
            return;
        }
    }

    setFlags(): void {
        // Twa flags
        const appVersion = get(this.rootQueryParams, "appVersion");
        const isTwaEnabledQueryParam = get(this.rootQueryParams, "isTwaEnabled");
        const isTwaEnabled = get((<any>window).EC_TWA || {}, "isTwaEnabled", false) || isTwaEnabledQueryParam == 1 || isTwaEnabledQueryParam == "true";
        TwaEventHandler.setEnabled(isTwaEnabled);
        TwaEventHandler.setLanguage(this.globalSettingService.getLanguage());
        TwaEventHandler.setMobileAppVersion(appVersion);
    }

    setRootQueryParams(): void {
        /*
         * Root query params should be parsed manually since it should be before router.initialNavigation()
         * InitialNavigation is prevented in order to prohibit "/browse" route to be loaded together with related API calls.
         * In the first place, page is redirected to specific a route (Ex: "teach", "myclass" or "myenglish") according to the properties of AccountIdentity.
         * Lastly initialNavigation() is triggered so that only related above specific route is loaded with its component without loading deeplinked "/browse" route before redirection.
         * */
        this.rootQueryParams = parseQueryParams(get(this.getLocation(), "1", ""));
    }

    setElementRefParams(): void {
        // Geolocation
        const routerGeolocation = get(this.rootQueryParams, "geolocation");
        this.appStateService.setGeolocation(routerGeolocation, this.elementRef);
        this.appStateService.setFormData(this.elementRef);
        this.appStateService.setIpAddress(this.elementRef);
    }

    isSettingsLoading(): boolean {
        return this.settingsLoading;
    }

    isAnonymous(): boolean {
        return this.identityService.isAnonymous();
    }

    isHomeRoute(): boolean {
        return this.router.isActive(ROUTE_HOME, {matrixParams: "ignored", queryParams: "ignored", paths: "subset", fragment: "ignored"});
    }

    isFooterTogglingEnabled(): boolean {
        // Partners have their own anonymous landing pages. Therefore featureKnob can not be set for them for some
        // anonymous cases. Footer settings is one of them.
        const rootRoute = this.appRoutingStateService.getRootUrlSegmentsMergedPaths();
        let partnerId = getPartnerIdFromRootRoute(rootRoute, this.route.snapshot.queryParamMap.get("partnerId"))
            || this.identityService.getPartnerId();
        if (partnerId && isFooterTogglingDisabledForPartner(partnerId)) {
            return false;
        }
        // Write additional logic here
        return true;
    }

    async isCookieConsentAccepted(): Promise<boolean> {
        return await this.cookieConsent.getCache({ "cookie-banner": 1 }).toPromise();
    }

    isCookieConsentFeatureEnabled(): boolean {
        let isCookieConsentFeatureEnabled = this.featureService.getFeature("isCookieConsentEnabled") ?? false;
        if (typeof isCookieConsentFeatureEnabled === "string") {
            isCookieConsentFeatureEnabled = !!toNumber(isCookieConsentFeatureEnabled);
        }
        return isCookieConsentFeatureEnabled;
    }

    hasPartnerOrigin(): boolean {
        return this.appRoutingStateService.isRootRoute(ROUTE_PARTNER_ROOT) || this.identityService.isPartner();
    }

    private isUrlParamTruthy(urlParam: any): boolean {
        return urlParam == 1 || urlParam == "true" || urlParam === true;
    }

    hasEnableInitialStepsQueryParam(): boolean {
        // IMPORTANT => initialNavigation is not triggered here yet. Therefore we need to get queryParams from location.
        return this.isUrlParamTruthy(this.route.snapshot.queryParamMap.get("enableInitialSteps") || getQueryParams()["enableInitialSteps"]);
    }

    isHeaderDisabled(): boolean {
        return this.isUrlParamTruthy(this.route.snapshot.queryParamMap.get("enableHideHeader") || getQueryParams()["enableHideHeader"]);
    }

    shouldRenderHeader(): boolean {
        return (!Browser.isAndroidMobileWebView() && !this.appRoutingStateService.isRouteRestrictionApplicableToHeader()) && !this.isHeaderDisabled();
    }

    shouldRenderPushNotification(): boolean {
        return !TwaEventHandler.isTwaEnabled();
    }

    launchTwa(): void {
        // check if launch twa is true, always attempt to open mobile app
        const launchTwa = get(this.rootQueryParams, "launchTwa");
        if (launchTwa) {
            return TwaEventHandler.deeplink(get(this.getLocation(), "0", ""));
        }
    }

    openFreeLessonPromo(): void {
        const freeLessonPromoModalRef = this.modalLaunchService.open(
            FreelessonPromoAppModalComponent,
            SchedulerApp.MODAL_OPTIONS
        );
        freeLessonPromoModalRef.componentInstance.eventClose
            .pipe(takeUntil(this.getDestroyInterceptor()))
            .subscribe((closedWithManualRedirection) => {
                this.modalLaunchService.close();
                if (!this.appRoutingStateService.isActiveRoute(ROUTE_MY_ENGLISH) && !closedWithManualRedirection) {
                    this.router.navigate([ROUTE_MY_ENGLISH]);
                }
            });
    }

    initializeIdentity(): Promise<AccountIdentity> {
        return this.identityService
            .initialize(undefined, undefined);
    }

    private async lazyLoadAsyncComponents(): Promise<void> {
        await this.lazyLoadHeaderNavigationModule();
        await this.lazyLoadGlobalNotificationModule();
        await this.lazyLoadPushNotificationModule();
        await this.lazyLoadFooterModule();
        await this.lazyLoadCookieConsentModule();
        // @INFO: Delete queryParam chat when client chat is enabled
        if (this.route.snapshot.queryParamMap.get("isTwilioChatDebuggerEnabled")) {
            await this.lazyLoadTwilioChatDebuggerModule();
        } else {
            // @INFO: Twilio site chat is loaded for every user. It is a shell which
            // @INFO: decides how or when it is going to be loaded. If it does not
            // @INFO: need to be turned on, the module does not turn itself on.
            await this.lazyLoadTwilioSiteChatModule();
        }
    }

    private async lazyLoadTwilioSiteChatModule(): Promise<void> {
        const { TwilioSiteChatModule } = await import("./modules/twilio-site-chat/twilio-site-chat.module");
        const { TwilioSiteChatComponent } = await import("./modules/twilio-site-chat/twilio-site-chat.component");
        await this.lazyLoaderService.load(
            this.twilioSiteChatRef,
            TwilioSiteChatComponent,
            TwilioSiteChatModule
        );
    }

    private async lazyLoadTwilioChatDebuggerModule(): Promise<boolean> {
        if (this.identityService.isAnonymous()) {
            return Promise.resolve(false);
        }
        const { TwilioChatFloatModule } = await import("./modules/twilio-chat-app/twilio-chat-float/twilio-chat-float.module");
        const { TwilioChatFloatComponent } = await import("./modules/twilio-chat-app/twilio-chat-float/twilio-chat-float.component");
        const { TwilioChatHelper, TwilioChatMode } = await import("./modules/twilio-chat-app/twilio-chat.helpers");
        const { TwilioChatConfig } = await import("./modules/twilio-chat-app/twilio-chat.config");

        let componentRef = await this.lazyLoaderService.load(
            this.twilioChatDebuggerRef,
            TwilioChatFloatComponent,
            TwilioChatFloatModule
        );

        try {
            const config = new TwilioChatConfig();
            const ldapIdFromQueryParam = this.route.snapshot.queryParamMap.get("ldapId");
            const languagesFromQueryParam = this.route.snapshot.queryParamMap.get("languages");
            const sessionId = this.route.snapshot.queryParamMap.get("sessionId");
            const mode = this.route.snapshot.queryParamMap.get("chatMode");
            config.identity = {
                id: ldapIdFromQueryParam || TwilioChatHelper.CRM_MESSAGE_AUTHORS[2]
            };
            // @INFO: DEBUG_CMS_FROM_CLIENT_ENABLED is enabled for debugging CMS_SUPPORT and CMS_LESSON modes from client side.
            TwilioChatHelper.DEBUG_CMS_FROM_CLIENT_ENABLED = true;
            // @INFO: DEBUG_CHAT_WIZARD is enabled to debug CMS_CHAT_WIZARD and CLIENT_CHAT_WIZARD. Enabling this makes all sessions valid.
            TwilioChatHelper.DEBUG_CHAT_WIZARD = true;
            // @INFO: Switch this to TwilioChatMode.CLIENT_SUPPORT to debug CLIENT_SUPPORT
            // @ts-ignore
            config.mode = mode || TwilioChatMode.CMS_SUPPORT;
            if (config.mode == TwilioChatMode.CMS_CHAT_WIZARD) {
                config.isLastMessageTimeAlertEnabled = false;
                config.isInformationStreamEnabled = true;
            }
            config.isEmojiSupportEnabled = true;
            config.isAttachmentsEnabled = true;
            config.isConversationPaginatorEnabled = true;
            config.isMessageOptionsEnabled = true;
            config.languages = languagesFromQueryParam;
            config.sessionId = sessionId ? toNumber(sessionId) : undefined;
            config.isMergedConversationsEnabled = false;
            this.twilioChatDebuggerComponentRef = componentRef;
            this.twilioChatDebuggerComponentRef.instance.config = config;
            return true;
        } catch (error) {
            Instrumentation.sendEvent("chat-wizard-error", {
                errorType: "Lazy loading error"
            });
            this.logger.error("[Twilio Chat][Error] Lazy loading error from landing-app.", error);
            return false;
        }
    }

    private async lazyLoadGlobalNotificationModule(): Promise<boolean> {
        const { GlobalNotificationAppModule } = await import("../global-notification-app/global-notification-app.module");
        const { GlobalNotificationAppComponent } = await import("../global-notification-app/global-notification-app.component");
        return await this.lazyLoaderService.load(
            this.globalNotificationRef,
            GlobalNotificationAppComponent,
            GlobalNotificationAppModule
        ).then(() => true).catch(() => false);
    }

    private async lazyLoadPushNotificationModule(): Promise<boolean> {
        if (!this.shouldRenderPushNotification()) {
            return;
        }

        const { PushNotificationModule } = await import("./modules/push-notification/push-notification.module");
        const { PushNotificationComponent } = await import("./modules/push-notification/push-notification.component");
        return await this.lazyLoaderService.load(
            this.pushNotificationRef,
            PushNotificationComponent,
            PushNotificationModule
        ).then(() => true).catch(() => false);
    }

    private async lazyLoadFooterModule(): Promise<boolean> {
        const { FooterModule } = await import("./modules/footer/footer.module");
        const { FooterComponent } = await import("./modules/footer/footer.component");
        return this.lazyLoaderService.load(
            this.footerRef,
            FooterComponent,
            FooterModule
        ).then((componentRef: ComponentRef<FooterComponent>) => {
            this.footerComponentRef = componentRef;
            this.footerComponentRef.instance.hasPartnerOrigin = this.hasPartnerOrigin();
            this.footerComponentRef.instance.togglingEnabled = this.isFooterTogglingEnabled();
            this.footerComponentRef.instance.geolocation = this.getGeolocation();
            return true;
        }).catch(() => false);
    }

    private async lazyLoadHeaderNavigationModule(): Promise<boolean> {
        // If header navigation module is already lazy loaded do not try to render/load it again.
        if (!isUndefined(this.headerNavigationAppComponentRef)) {
            return Promise.resolve(true);
        }
        const { HeaderNavigationAppModule } = await import("./modules/header-navigation-app/header-navigation-app.module");
        const { HeaderNavigationAppComponent } = await import("./modules/header-navigation-app/header-navigation-app.component");
        return this.lazyLoaderService.load(
            this.headerNavigationAppRef,
            HeaderNavigationAppComponent,
            HeaderNavigationAppModule
        ).then((componentRef: ComponentRef<any>) => {
            this.headerNavigationAppComponentRef = componentRef;
        }).then(() => {
            if (!this.isAnonymous()
                && this.hasEnableInitialStepsQueryParam()
                && !TwaEventHandler.isTwaEnabled()
            ) {
                this.eligibilityService.initialize().toPromise()
                    .then(() => {
                        this.logger.log("Opening initial steps modal...");
                        this.channelService.publish(CHANNEL_OPEN_INITIAL_STEPS_MODAL);
                    }).then(() => {
                    // @INFO Initial steps initialization should be removed from the header once completed
                        return this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: { enableInitialSteps: 0 },
                            queryParamsHandling: "merge",
                            replaceUrl: true
                        });
                    });
            }
            return true;
        }).catch((e) => {
            this.logger.log("Unable to lazy load header navigation", e);
            return false;
        });
    }

    private async lazyLoadCookieConsentModule(): Promise<boolean> {
        const isCookieConsentFeatureEnabled = this.isCookieConsentFeatureEnabled();
        const isCookieConsentAccepted = await this.isCookieConsentAccepted();
        if (!this.isAnonymous() || !isCookieConsentFeatureEnabled || isCookieConsentAccepted) {
            return Promise.resolve(false);
        }
        const { CookieConsentModule } = await import("./modules/cookie-consent/cookie-consent.module");
        const { CookieConsentComponent } = await import("./modules/cookie-consent/cookie-consent.component");
        return this.lazyLoaderService.load(
            this.cookieConsentRef,
            CookieConsentComponent,
            CookieConsentModule
        ).then((componentInstance) => {
            // This should stay as a subscription. Do not convert it a chained promise.
            componentInstance.instance.eventAcceptConsent
                .pipe(
                    take(1)
                ).subscribe(() => this.cookieConsent.setValue({ "cookie-banner": 1 }, true));
            return true;
        }).catch(() => false);
    }

    private trackPushNotifications(): void {
        const fromPushnotification = get(this.rootQueryParams, "frompushnotification");
        if (fromPushnotification) {
            this.pushNotificationService.track({
                eventTypeID: get(this.rootQueryParams, "eventTypeID", EVENT_TYPE_OPEN),
                messageTemplateID: get(this.rootQueryParams, "messageTemplateID", 0),
                messageTransactionID: get(this.rootQueryParams, "messageTransactionID", 0),
                applicationStateLaunched: get(this.rootQueryParams, "applicationStateLaunched", APPLICATIONSTATELAUNCHED_ACTIVE)
            });
        }
    }

    getPartnerStyleClassName(): string {
        return getConfigByPartnerId(this.identityService.getPartnerId())?.styleClassName ?? "";
    }

    isPertoPluginEnabled(): boolean {
        return this.featureService.getFeature("isPertoPluginEnabled");
    }

    ngOnDestroy(): void {
        this.destroySubscriptions();
    }
}
